// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// eslint-disable-next-line no-unused-vars
const baseURL = window.API_URL || 'https://fp-dev3.fkcore.org/api/v2'

export const getUser = createAsyncThunk('account/getUser', async () => {
  const response = await axios.get(`${baseURL}/users`)
  return {data: response.data }
})

export const getIpWhitelist = createAsyncThunk('account/getIpWhitelist', async () => {
  const response = await axios.get(`${baseURL}/security/ip-whitelist`)
  return {data: response.data }
})

export const updateIpWhitelist = createAsyncThunk('account/updateIpWhitelist', async (data) => {
  const response = await axios.put(`${baseURL}/security/ip-whitelist`, { ...data })
  return {data: response.data }
})

export const enable_authentication_factor = createAsyncThunk('account/enable_authentication_factor', async (type) => {
  const response = await axios.post(`${baseURL}/security/mfa/${type}/enable`)
  return {data: response.data }
})

export const disable_authentication_factor = createAsyncThunk('account/disable_authentication_factor', async (data) => {
  const response = await axios.post(`${baseURL}/security/mfa/${data.type}/disable`)
  return {data: response.data }
})

export const confirm_authentication_factor = createAsyncThunk('account/confirm_authentication_factor', async (data) => {
  const response = await axios.post(`${baseURL}/security/mfa/${data.type}/confirm`, data.code && {code: data.code, type: data.type})
  return {data: response.data }
})

export const updateUserSate = createAsyncThunk('account/updateUserSate',  data => {
  return {data}
})

// eslint-disable-next-line no-unused-vars
export const updateUser = createAsyncThunk('account/updateUser', async (data, { rejectWithValue })  => {
  try {
    const response = await axios.put(`${baseURL}/users`, { ...data })
    return { data: response }
  } catch (error) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue({error: error.response.data})
  }
})

export const appUsersSlice = createSlice({
  name: 'user',
  initialState: {
    user: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUser.fulfilled, (state, action) => {
        state.user = action.payload.data
      })
      .addCase(updateUserSate.fulfilled, (state, action) => {
        state.user = action.payload.data
      })
  }
})

export default appUsersSlice.reducer
