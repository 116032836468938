// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

const baseURL = window.API_URL || 'https://fp-dev3.fkcore.org/api/v2'

export const getReports = createAsyncThunk('appEcommerce/reports', async params => {
  const siteBalanceReportType = params.siteBalanceReportType
  delete params.siteBalanceReportType
  const response = await axios.get(`${baseURL}/site-balance-reports/${siteBalanceReportType}`, {params})
  return {params, data: response.data}
})

export const getReport = createAsyncThunk('appEcommerce/report', async params => {
  const siteBalanceReportType = params.siteBalanceReportType
  const id = params.id
  delete params.siteBalanceReportType
  delete params.id
  const response = await axios.get(`${baseURL}/site-balance-reports/${siteBalanceReportType}/${id}`, {params})
  return {params, data: response.data}
})

export const suggest = createAsyncThunk('appEcommerce/suggest', async params => {
  const name = params.name
  delete params.name
  const response = await axios.get(`${baseURL}/suggester/suggest/${name}`, {params})
  return {params, data: response.data}
})

export const appEcommerceSlice = createSlice({
  name: 'appEcommerce',
  initialState: {
    records: [],
    params: {},
    limit: 0,
    offset: 0
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getReports.fulfilled, (state, action) => {
        state.records = action.payload.data.items
        state.total = action.payload.data.total
        state.params = action.payload.params
        state.limit = action.payload.data.limit
        state.offset = action.payload.data.offset
      })
  }
})

export default appEcommerceSlice.reducer
