// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

const baseURL = window.API_URL || 'https://fp-dev3.fkcore.org/api/v2'

export const getSiteBalances = createAsyncThunk('appEcommerce/getSiteBalances', async params => {
  const response = await axios.get(`${baseURL}/site-balances`, {params})
  return {params, data: response.data}
})

export const getSiteBalance = createAsyncThunk('appEcommerce/getSiteBalance', async id => {
  const response = await axios.get(`${baseURL}/site-balances/${id}`)
  return {data: response.data}
})

export const getSite = createAsyncThunk('appEcommerce/getSite', async (id) => {
  const response = await axios.get(`${baseURL}/sites/${id}`)
  return {data: response.data }
})

export const suggest = createAsyncThunk('appEcommerce/suggest', async params => {
  const name = params.name
  delete params.name
  const response = await axios.get(`${baseURL}/suggester/suggest/${name}`, { params })
  return {params, data: response.data }
})

export const appEcommerceSlice = createSlice({
  name: 'appEcommerce',
  initialState: {
    params: {},
    limit: 0,
    siteBalances: [],
    siteBalance: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getSiteBalances.fulfilled, (state, action) => {
        state.siteBalances = action.payload.data.items
        state.params = action.payload.params
        state.limit = action.payload.data.limit
        state.offset = action.payload.data.offset
      })
      .addCase(getSiteBalance.fulfilled, (state, action) => {
        state.siteBalance = action.payload.data
      })
  }
})

export default appEcommerceSlice.reducer
