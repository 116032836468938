// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

const baseURL = window.API_URL || 'https://fp-dev3.fkcore.org/api/v2'

export const getSites = createAsyncThunk('appEcommerce/getSites', async params => {
  const response = await axios.get(`${baseURL}/sites`, { params })
  return { params, data: response.data }
})

export const getSite = createAsyncThunk('appEcommerce/getSite', async (id) => {
  let errors = {}
  const response = await axios.get(`${baseURL}/sites/${id}`).catch(error => {
    if (error && error.response && error.response.data) {
      errors = error.response.data
    }
  })
  if (response) {
    return {data: response.data, errors}
  } else {
    return {errors}
  }
})

export const suggest = createAsyncThunk('appEcommerce/suggest', async params => {
  const name = params.name
  delete params.name
  const response = await axios.get(`${baseURL}/suggester/suggest/${name}`, { params })
  return {params, data: response.data }
})

export const regenerateSecretKey = createAsyncThunk('appEcommerce/regenerate-secret-key', async (id) => {
  const response = await axios.post(`${baseURL}/sites/${id}/regenerate-secret-key`)
  return {data: response.data }
})

export const regenerateApiSecret = createAsyncThunk('appEcommerce/regenerate-api-secret', async (id) => {
  const response = await axios.post(`${baseURL}/sites/${id}/regenerate-api-secret`)
  return {data: response.data }
})

export const getIpWhitelist = createAsyncThunk('account/getIpWhitelist', async (id) => {
    const response = await axios.get(`${baseURL}/sites/${id}/ip-whitelist`)
    return {data: response.data }
})

export const updateIpWhitelist = createAsyncThunk('account/updateIpWhitelist', async (data) => {
    const response = await axios.put(`${baseURL}/sites/${data.id}/ip-whitelist`, { ...data })
    return {data: response.data }
})

export const siteAccountBalances = createAsyncThunk('appEcommerce/siteAccountBalances', async params => {
  let errors = {}
  const response = await axios.get(`${baseURL}/site-account-balances`, {params}).catch(error => {
    if (error && error.response && error.response.data) {
      errors = error.response.data
    }
  })
  if (response) {
    return {params, data: response.data, errors}
  } else {
    return {params, errors}
  }
})

export const getSiteAccountsList = createAsyncThunk('appEcommerce/getSiteAccountsList', async id => {
  let errors = {}
  const response = await axios.get(`${baseURL}/sites/${id}/accounts`).catch(error => {
    if (error && error.response && error.response.data) {
      errors = error.response.data
    }
  })
  if (response) {
    return {data: response.data, errors}
  } else {
    return {errors}
  }
})

export const appEcommerceSlice = createSlice({
  name: 'appEcommerce',
  initialState: {
    params: {},
    limit: 0,
    total: 0,
    sites: [],
    site: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
        .addCase(getSites.fulfilled, (state, action) => {
            state.sites = action.payload.data.items
            state.params = action.payload.params
            state.limit = action.payload.data.limit
            state.total = action.payload.data.total
            state.offset = action.payload.data.offset
        })
        .addCase(getSite.fulfilled, (state, action) => {
          if (action.payload.data) {
            state.site = action.payload.data
          } else {
            state.site = []
          }
      })
  }
})

export default appEcommerceSlice.reducer
