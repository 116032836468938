// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

const baseURL = window.API_URL || 'https://fp-dev3.fkcore.org/api/v2'

export const getNotifications = createAsyncThunk('appEcommerce/getNotifications', async params => {
  let errors = {}
  const response = await axios.get(`${baseURL}/notifications`, {params}).catch(error => {
    if (error && error.response && error.response.data) {
      errors = error.response.data
    }
  })
  if (response) {
    return {params, data: response.data, errors}
  } else {
    return {params, errors}
  }
})

export const getNotification = createAsyncThunk('appEcommerce/Notification', async id => {
  const response = await axios.get(`${baseURL}/notifications/${id}`)
  return {data: response.data}
})

export const repeatNotification = createAsyncThunk('appEcommerce/repeatNotification', async id => {
  const response = await axios.post(`${baseURL}/notifications/${id}/repeat`)
  return {data: response.data}
})

export const getSite = createAsyncThunk('appEcommerce/getSite', async (id) => {
  const response = await axios.get(`${baseURL}/sites/${id}`)
  return {data: response.data }
})

export const suggest = createAsyncThunk('appEcommerce/suggest', async params => {
  const name = params.name
  delete params.name
  const response = await axios.get(`${baseURL}/suggester/suggest/${name}`, { params })
  return {params, data: response.data }
})

export const appEcommerceSlice = createSlice({
  name: 'appEcommerce',
  initialState: {
    params: {},
    limit: 0,
    notifications: [],
    notification: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getNotifications.fulfilled, (state, action) => {
        if (action.payload.data) {
          state.notifications = action.payload.data.items
          state.params = action.payload.params
          state.total = action.payload.data.total
          state.limit = action.payload.data.limit
          state.offset = action.payload.data.offset
        } else {
          state.notifications = []
          state.params = action.payload.params
          state.total = []
          state.limit = []
          state.offset = []
        }
      })
      .addCase(getNotification.fulfilled, (state, action) => {
        state.notification = action.payload.data
      })
  }
})

export default appEcommerceSlice.reducer
